import React from "react";
import { Photo } from "../../components/Photo/Photo";

import "./gallerypage.css";

export const GalleryPage = () => {
  return (
    <div className="gallerypage">
      <h1>2024-2025</h1>
      <section className="gallerypage--photo-section">
        <Photo name="newsfeed/2025-03-17/image1.jpeg" size="small" gallery />
        <Photo name="newsfeed/2025-03-17/image2.jpeg" size="small" gallery />
        <Photo name="newsfeed/2025-03-17/image3.jpeg" size="small" gallery />
        <Photo name="newsfeed/2025-03-17/image4.jpeg" size="small" gallery />
        <Photo name="newsfeed/2025-03-17/image5.jpeg" size="small" gallery />
        <Photo name="newsfeed/2025-03-17/image6.jpeg" size="small" gallery />
        <Photo name="newsfeed/2025-03-17/image7.jpeg" size="small" gallery />
        <Photo name="newsfeed/2025-03-17/image8.jpeg" size="small" gallery />
        <Photo name="newsfeed/2025-03-17/image9.jpeg" size="small" gallery />
      </section>
    </div>
  );
};
