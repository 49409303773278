import React, { useState, useEffect } from "react";
import winnersLogo from "../../assets/logos/axemen-transparent.png";
import trophyImage from "../../assets/league/league-cup.png";

import "./championsbanner.css";

export const ChampionsBanner = () => {
  return (
    <div className="champions-banner">
      <img
        src={winnersLogo}
        alt="Winners Logo"
        className="champions-banner--logo"
      />
      <h1>2024-25 CMHL CHAMPIONS</h1>
      <img
        src={trophyImage}
        alt="CMHL Trophy"
        className="champions-banner--trophy"
      />
    </div>
  );
};
