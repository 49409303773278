import React, { useEffect, useState } from "react";
import { Schedule } from "../../components/Schedule/Schedule";
import { Dropdown } from "../../components/Dropdown/Dropdown";
import { ScheduleLegend } from "../../components/ScheduleLegend/ScheduleLegend";
import axios from "axios";

import "./schedulepage.css";

export const SchedulePage = () => {
  const [selectedYear, setSelectedYear] = useState("2024-25");
  const [schedule, setSchedule] = useState([]);
  const [error, setError] = useState(null);

  const handleYearChange = (year) => {
    setSelectedYear(year);
  };

  useEffect(() => {
    const fetchSchedule = async () => {
      const url = `https://cmhlniagara.com/api/${
        selectedYear === "2023-24" ? "2023_schedule" : "2024_schedule"
      }`;
      try {
        const response = await axios.get(url);
        setSchedule(response.data);
      } catch (e) {
        setError(`ERROR: Failed to fetch ${selectedYear} Schedule`);
      }
    };

    fetchSchedule();
  }, [selectedYear]);

  return (
    <div className="schedulepage">
      <div className="schedulepage--body">
        <div className="schedulepage--header">
          <h1>Schedule</h1>
          <Dropdown yearSelection={handleYearChange} />
        </div>
        {error ? <h2>{error}</h2> : <Schedule scheduleData={schedule} />}
        <ScheduleLegend />
      </div>
    </div>
  );
};

export default SchedulePage;
