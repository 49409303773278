import React, { useState, useEffect } from "react";
import { ChampionsBanner } from "../ChampionsBanner/ChampionsBanner";
import { Scoreboard } from "../Scoreboard/Scoreboard";
import "./header.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * This Header component is displayed on the top of every web page. It contains the league logo and
 * the title of the league.
 */

// The UI for a Header component
export const Header = () => {
  return (
    <header className="header">
      <ChampionsBanner />
      {/** <Scoreboard /> */}
    </header>
  );
};
