import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MailIcon } from "@heroicons/react/outline";
import { Button } from "../Button/Button";
import { Logo } from "../Logo/Logo";

import "./navbar.css";

const getValues = (width) => {
  if (width >= 1440) {
    return { logoSize: 160, buttonSize: "desktop" };
  } else if (width >= 1024) {
    return { logoSize: 135, buttonSize: "laptop" };
  } else if (width >= 768) {
    return { logoSize: 90, buttonSize: "tablet" };
  } else {
    return { logoSize: 45, buttonSize: "mobile" };
  }
};

export const NavBar = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { logoSize, buttonSize } = getValues(windowWidth);

  /* COMMENTS TO BE REMOVED AS OF START OF 2025-26 SEASON */

  return (
    <div className="navbar">
      <div className="navbar--black-line" />
      <div className="navbar--white-line" />
      <nav>
        <Link to="/" className="navbar--logo">
          <Logo size={logoSize} padding={2} />
        </Link>
        <Link to="/schedule" className="navbar--link">
          <Button label="Schedule" size={buttonSize} primary />
        </Link>
        <Link to="/standings" className="navbar--link">
          <Button label="Standings" size={buttonSize} primary />
        </Link>
        <Link to="/stats" className="navbar--link">
          <Button label="Stats" size={buttonSize} primary />
        </Link>
        <Link to="/info" className="navbar--link">
          <Button label="Info" size={buttonSize} primary />
        </Link>
        {/**<Link to="/gallery" className="navbar--link">
          <Button label="Gallery" size={buttonSize} primary />
        </Link> */}
        <div className="navbar--contact">
          <MailIcon style={{ width: 24, padding: 5 }} />
          <p>cmhlniagara@gmail.com</p>
        </div>
      </nav>
      <div className="navbar--white-line" />
      <div className="navbar--black-line" />
    </div>
  );
};
