import React from "react";
import { MailIcon } from "@heroicons/react/outline";

import leagueLogo from "../../assets/logos/logo-transparent-white.png";
import axemenLogo from "../../assets/logos/axemen-transparent.png";
import gullsLogo from "../../assets/logos/gulls-transparent.png";
import jagrbombsLogo from "../../assets/logos/jagrbombs-transparent.png";
import mightyDrunksLogo from "../../assets/logos/mightydrunks-transparent.png";
import rockiesLogo from "../../assets/logos/rockies-transparent.png";
import toonieTuesdayLogo from "../../assets/logos/toonietuesday-transparent.png";

import "./footer.css";

/**
 * NAME: Rob Worron
 * DATE: September 29th, 2023
 */

/**
 * This Footer component is used on the bottom of every page. Currently, it only contains the date
 * in which the league was established.
 */

// The UI for a Footer component
export const Footer = () => (
  <footer className="footer">
    <div className="footer--red-line" />
    <div className="footer--white-line" />
    <div className="footer--logos">
      <img src={axemenLogo} alt="Axemen" className="footer--team-logo" />
      <a
        href="https://www.instagram.com/gullsnia"
        className="footer--team-logo"
      >
        <img src={gullsLogo} alt="Gulls" className="footer--team-logo" />
      </a>
      <a
        href="https://www.instagram.com/jagr.bombss"
        className="footer--team-logo"
      >
        <img
          src={jagrbombsLogo}
          alt="Jagrbombs"
          className="footer--team-logo"
        />
      </a>
      <img src={leagueLogo} alt="CMHL" className="footer--league-logo" />
      <img
        src={mightyDrunksLogo}
        alt="Mighty Drunks"
        className="footer--team-logo"
      />
      <img src={rockiesLogo} alt="Rockies" className="footer--team-logo" />
      <a
        href="https://www.instagram.com/toonie.tuesday"
        className="footer--team-logo"
      >
        <img
          src={toonieTuesdayLogo}
          alt="Toonie Tuesday"
          className="footer--team-logo"
        />
      </a>
    </div>
    <p>est. 2023</p>
    <h4 style={{ marginBottom: 0 }}>
      Website Designed & Developed by Robert Worron
    </h4>
    <div className="footer--email-info">
      <MailIcon style={{ width: 24, padding: 3 }} />
      <h5 style={{ margin: 0 }}>rworron94@gmail.com</h5>
    </div>
  </footer>
);
